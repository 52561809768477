import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

// Connects to data-controller="map"
export default class extends Controller {
  static targets = [ "mapContainer" ]
  static values = { latitude: Number, longitude: Number }

  connect() {
    this.createMap();
  }

  createMap() {

    const latitude = parseFloat(this.latitudeValue);
    const longitude = parseFloat(this.longitudeValue);

    this.map = L.map(this.mapContainerTarget).setView([latitude, longitude], 13);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    L.marker([latitude, longitude]).addTo(this.map);
  }

  disconnect() {
    this.map.remove();
  }
}

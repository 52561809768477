import { Controller } from "@hotwired/stimulus";
import L from "leaflet";

export default class extends Controller {
  static targets = ["itineraryMapContainer"];
  static values = {
    homeLatitude: Number,
    homeLongitude: Number,
    workLatitude: Number,
    workLongitude: Number,
    users: Array,
    preusers: Array
  };

  connect() {
    this.createMap();
  }

  createMap() {
    const homeLat = parseFloat(this.homeLatitudeValue);
    const homeLng = parseFloat(this.homeLongitudeValue);
    const workLat = parseFloat(this.workLatitudeValue);
    const workLng = parseFloat(this.workLongitudeValue);
    const users = this.usersValue;
    const preusers = this.preusersValue;

    const centerLat = (homeLat + workLat) / 2;
    const centerLng = (homeLng + workLng) / 2;

    this.map = L.map(this.itineraryMapContainerTarget).setView([centerLat, centerLng], 13);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 20,
      attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>"
    }).addTo(this.map);

    const homeIcon = L.divIcon({
      html: "<i class=\"fas fa-home green-icon\" style=\"font-size: 20px;\"></i>",
      iconSize: [20, 20],
      iconAnchor: [10, 20]
    });

    const workIcon = L.divIcon({
      html: "<i class=\"fas fa-building green-icon\" style=\"font-size: 20px;\"></i>",
      iconSize: [20, 20],
      iconAnchor: [10, 20]
    });

    const userIcon = L.divIcon({
      html: "<i class=\"fas fa-user\" style=\"font-size: 20px;\"></i>",
      iconSize: [20, 20],
      iconAnchor: [10, 20]
    });

    const preUserIcon = L.divIcon({
      html: "<i class=\"fas fa-user-alt-slash red-icon\" style=\"font-size: 20px;\"></i>",
      iconSize: [20, 20],
      iconAnchor: [10, 20]
    });

    L.marker([homeLat, homeLng], { icon: homeIcon }).addTo(this.map).bindPopup("Maison");
    L.marker([workLat, workLng], { icon: workIcon }).addTo(this.map).bindPopup("Travail");

    users.forEach(user => {
      const daySchedulesHtml = user.day_schedules.map(schedule => {
        return `<span>
          ${schedule.day}: <span class="fw-bold">${new Date(schedule.departure_home_time).getHours()} -> ${new Date(schedule.departure_work_time).getHours()}H</span>
        </>`;
      }).join("");

      const popupContent = `
        ID: ${user.id} / ${user.first_name} ${user.last_name} / ${user.phone_number} / ${user.company_name}
        \n / ${user.travel_preferences.gender_preference} /  ${user.travel_preferences.price} FCFA
        \n
        <p>${daySchedulesHtml}</p>
      `;

      L.marker(
        [user.home_address_latitude, user.home_address_longitude],
        { icon: userIcon }
      )
        .addTo(this.map)
        .bindPopup(popupContent);
    });

    preusers.forEach(preUser => {
      const popupContent = `
  <div class="popup-content">
    <p class="m-0"><i class="fas fa-id-badge"></i> <strong>${preUser.id}</strong> / ${preUser.first_name} ${preUser.last_name} / <i class="fas fa-venus-mars"></i> ${preUser.gender}</p>
    <p><i class="fas fa-building"></i> <strong>Company:</strong> ${preUser.company_name} / <i class="fas fa-tag"></i> <strong>Referral Code:</strong> ${preUser.referral_code || "N/A"} &nbsp; <i class="fas fa-check-circle"></i> <strong>Status:</strong> ${preUser.status}</p>
    
    <p class="m-0"><i class="fas fa-phone"></i> ${preUser.country_code || ""} ${preUser.phone_number} / <i class="fas fa-envelope"></i> ${preUser.email}</p>
    <p><i class="fas fa-map-marker-alt"></i> <strong>Maison:</strong> ${preUser.address} / <i class="fas fa-map-marker-alt"></i> <strong>Travail:</strong> ${preUser.work_address}</p>
    <p class="m-0"><i class="fas fa-calendar-alt"></i> <strong>Carpooling Days:</strong> ${preUser.carpooling_days}</p>
    <p><i class="fas fa-home"></i> ${new Date(preUser.departure_home_time).toLocaleTimeString()} / <i class="fas fa-briefcase"></i> ${new Date(preUser.departure_work_time).toLocaleTimeString()}</p>
    <p><i class="fas fa-car"></i> <strong>Vehicle:</strong> ${preUser.has_vehicule ? "Yes" : "No"} / <i class="fas fa-user-friends"></i> Places: ${preUser.available_place ?? '0'} /
    <i class="fas fa-dollar-sign"></i> <strong>Price:</strong> ${preUser.price} FCFA
    <i class="fas fa-car-side"></i> <strong>Make:</strong> ${preUser.vehicule_make ?? ''} / <i class="fas fa-gas-pump"></i> Carburant: ${preUser.vehicule_fuel_type ?? ''}</p>
  </div>
`;

      L.marker(
        [preUser.home_latitude, preUser.home_longitude],
        { icon: preUserIcon }
      )
        .addTo(this.map)
        .bindPopup(popupContent);
    });
  }

  disconnect() {
    this.map.remove();
  }
}
